(function($) {
  var arrow = '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 33.75 13.39"><defs><style>.cls-1,.cls-2{fill:none;}.cls-1{clip-rule:evenodd;}.cls-3{clip-path:url(#clip-path);}.cls-4{clip-path:url(#clip-path-2);}.cls-5{fill:#1c3144;}</style><clipPath id="clip-path"><polygon class="cls-1" points="7.42 5.79 31.88 5.79 31.88 7.6 7.42 7.6 7.42 12.24 1.88 6.69 7.42 1.14 7.42 5.79"/></clipPath><clipPath id="clip-path-2"><rect class="cls-2" x="-74.12" y="-3651.86" width="1440" height="4305"/></clipPath></defs><title>Artboard 1</title><g class="cls-3"><g class="cls-4"><rect class="cls-5" x="-3.12" y="-3.86" width="40" height="21.1"/></g></g></svg>';
  
  $(document).foundation();

  var windowHeight;
  var Reveal;
  
  function SizeContent() {
    var fullHeight = $(window).height();
    var headerHeight = $('body > header').outerHeight();

    $(".js-full-height").css("height", fullHeight);
    $(".js-full-height--no-header").css("height", (fullHeight - headerHeight));
  }

  $(document).on('click', '.js-scrollto', function(e) {
    e.preventDefault();
    var target = $(this).data('target'),
        targetPosition = $(target).offset().top;

    if($(this).hasClass('js-scrollto--process')) {
      targetPosition = targetPosition + $(target).outerHeight() - $(window).height();
    }

    $('body, html').stop().animate({scrollTop:targetPosition}, '300', 'swing');
  });

  $(document).on('click', '.menu-toggle',function(e) {
    $(this).toggleClass('js-active');
    $('body > header').toggleClass('js-expanded');
  });

  function ScrollAnimes(windowHeight) {
    var controller = new ScrollMagic.Controller(),
      offset = windowHeight / 2,
      duration = 1000;

    // Analyze animation
    var timelineAnalyze = new TimelineMax({repeat: -1});
    timelineAnalyze
      .to("#analyze-magnifier", 0.6, { x: -90, y:-27, delay:0.6 })
      .to("#analyze-square", 0.6, { scale: 1.2, transformOrigin:"50% 50%", fill: '#2FAB66' }, "-=0.4")
      .to("#analyze-square", 0.6, { scale: 1, transformOrigin:"50% 50%", fill: '#FFFFFF' }, "+=0.8")
      .to("#analyze-magnifier", 0.6, { x: -163, y:-24 }, "-=0.7")
      .to("#analyze-pyramid", 0.6, { scale: 1.2, transformOrigin:"50% 50%", fill: '#FF3434' }, "-=0.6")
      .to("#analyze-pyramid", 0.6, { scale: 1, transformOrigin:"50% 50%", fill: '#FFFFFF' }, "+=0.8")
      .to("#analyze-magnifier", 0.6, { x: -163, y:51 }, "-=0.7")
      .to("#analyze-circle", 0.6, { scale: 1.2, transformOrigin:"50% 50%", fill: '#2FAB66'}, "-=0.6")
      .to("#analyze-circle", 0.6, { scale: 1, transformOrigin:"50% 50%", fill: '#FFFFFF' }, "+=0.8")
      .to("#analyze-magnifier", 0.6, { x: -90, y:51 }, "-=0.7")
      .to("#analyze-polygon", 0.6, { scale: 1.1, transformOrigin:"50% 50%", fill: '#FF3434' }, "-=0.6")
      .to("#analyze-polygon", 0.6, { scale: 1, transformOrigin:"50% 50%", fill: '#FFFFFF' }, "+=0.8")
      .to("#analyze-magnifier", 0.6, { x: 0, y:0 }, "-=0.7")
      ;

    var sceneAnalyze = new ScrollMagic.Scene({triggerElement: ".process__item--1"})
      .addTo(controller);

    // Improve animation
    var tweenImprove = TweenMax.from("#Piece_1", 1, {rotation: 35, x: 160, y: -200, scale:1.2, ease: Power3.easeIn});
    
    var sceneImprove = new ScrollMagic.Scene({triggerElement: ".process__item--2", duration: duration, offset: offset});
      sceneImprove.setTween(tweenImprove)
      .setPin(".process__item--2 > div")
      .addTo(controller);


    // Implement animation
    var tweenImplement = new TimelineMax();
    tweenImplement
      .from(".implement-circle", 1.5, {transformOrigin:"50% 50%", scale: 0})
      .to(".implement-gog-1", 1.5, {transformOrigin:"50% 50%", rotation: 360}, "-=1.5")
      .to(".implement-gog-2", 1.5, {transformOrigin:"50% 50%", rotation: -360}, "-=1.5")
      ;
    
    var sceneImplement = new ScrollMagic.Scene({triggerElement: ".process__item--3", duration: duration, offset:offset});
      sceneImplement.setTween(tweenImplement)
      .setPin(".process__item--3 > div")
      .addTo(controller);


    // Test animation
    var timelineTest = new TimelineMax();
    timelineTest
      .from("#test-1 .test-check", 1, {scale: 0, transformOrigin:"50% 50%", ease: Power3.easeIn})
      .from("#test-1 .test-line", 1, {attr:{width:0}, ease: Power3.easeIn})
      .from("#test-2 .test-false", 1, {scale: 0, transformOrigin:"50% 50%", ease: Power3.easeIn}, "-=0.3")
      .from("#test-2 .test-line", 1, {attr:{width:0}, ease: Power3.easeIn})
      .from("#test-3 .test-check", 1, {scale: 0, transformOrigin:"50% 50%", ease: Power3.easeIn}, "-=0.3")
      .from("#test-3 .test-line", 1, {attr:{width:0}, ease: Power3.easeIn})
      .from("#test-4 .test-check", 1, {scale: 0, transformOrigin:"50% 50%", ease: Power3.easeIn}, "-=0.3")
      .from("#test-4 .test-line", 1, {attr:{width:0}, ease: Power3.easeIn});

    var sceneTest = new ScrollMagic.Scene({triggerElement: ".process__item--4", duration: duration, offset:offset});
      sceneTest.setTween(timelineTest)
      .setPin(".process__item--4 > div")
      .addTo(controller);


    // Support animation
    var timelineSupport = new TimelineMax();
    timelineSupport
      .from("#support-face-1", 1, {scale: 0, y: 25, transformOrigin:"50% 50%", ease: Power3.easeIn})
      .from("#support-questionmark", 1, {scale: 0, y: 25,transformOrigin:"50% 50%", ease: Power3.easeIn}, "-=0.2")
      .from("#support-line-1", 0.1, {opacity:0}, "-=0.2")
      .from("#support-line-1", 1, {attr:{x2:"303"}, ease: Power3.easeIn}, "-=0.2")
      .from("#support-chat", 1, {scale: 0, transformOrigin:"50% 50%", ease: Power3.easeIn}, "-=0.2")
      .staggerFrom(".support-dot", 1, {scale: 0, y: 10, transformOrigin:"50% 50%", ease: Elastic.easeOut.config(1, 0.5)},0.4)
      .from("#support-line-2", 0.1, {opacity:0}, "-=0.2")
      .from("#support-line-2", 1, {attr:{x2:"516.5"}, ease: Power3.easeIn}, "-=0.2")
      .from("#support-face-2", 1, {scale: 0, transformOrigin:"50% 50%", ease: Power3.easeIn}, "-=0.2")
      .from("#support-checked", 1, {scale: 0, transformOrigin:"50% 50%", ease: Power3.easeIn}, "-=0.2");

    var sceneSupport = new ScrollMagic.Scene({triggerElement: ".process__item--5", duration: 1600, offset:offset});
      sceneSupport.setTween(timelineSupport)
      .setPin(".process__item--5 > div")
      .addTo(controller);

    var scenes = [sceneAnalyze, sceneImprove, sceneImplement, sceneTest, sceneSupport];
    
    $.each(scenes, function(index, value){
      value.on("enter", function (event) {
        $('.menu__item').removeClass('js-active');
          $('.menu__item--'+(index+1)).addClass('js-active');
      });
      value.on("leave", function (event) {
          if(index === 1 && event.scrollDirection === "REVERSE") {
            $('.menu__item').removeClass('js-active');
            $('.menu__item--1').addClass('js-active');
          }
      });
    });
  }

  var aboutBlockRevealed = false;
  function FrontPageAboutBlock() {
    var offset = $(window).height() - ($(window).height()/3);
    
    if(!aboutBlockRevealed) {
      $( '#about:in-viewport('+offset+')' ).run(function() {
        aboutBlockRevealed = true;
        var tl = new TimelineMax();
        
        tl.to('.about-block__image', 0.8, {opacity:"1", ease:Quart.easeInOut})
        .staggerTo('.about-block__text > *', 0.5, {opacity:"1", transform: "translate(0, 0)"}, 0.2);
      });
    }
  }

  var surveylabRevealed = false;
  function SurveylabBlock() {
    var offset = $(window).height() - ($(window).height()/3);
    
    if(!surveylabRevealed) {
      $( '.surveylab-items:in-viewport('+offset+')' ).run(function() {
        surveylabRevealed = true;
        var tl = new TimelineMax();
        
        tl.staggerTo('.surveylab-items__item', 0.5, {opacity:"1", transform: "translate(0, 0)", ease:Quart.easeInOut}, 0.2);    
      });
    }
  }

  var processReveealed = false;
  function ProcessBlock() {
    var offset = $(window).height() - ($(window).height()/3);
    
    if(!processReveealed) {
      $( '.home .process:in-viewport('+offset+')' ).run(function() {
        processReveealed = true;
        var tl = new TimelineMax();
        
        tl.staggerTo('.process__item', 0.5, {opacity:"1", ease:Quart.easeInOut}, 0.4);    
      });
    }
  }

  function HideItem($this) {
    $($this).hide();
  }

  $(document).on('click', '.splash .control-group input[type="radio"]', function(e) {

    var value = $(this).val();
    if($('.splash__answer[data-answer="'+value+'"]').hasClass('js-expanded')) {
      $(this).prop("checked", false).blur();

      $('.splash__answer').removeClass('js-expanded');
    } else {
      $('.splash__answer').removeClass('js-expanded');
      $('.splash__answer[data-answer="'+value+'"]').addClass('js-expanded');
    }
    
  });

  function ContactFormOverlay() {
    var formOverlay = $('.form-overlay');
    var formOpen = false;

    $('.contact-button').click(function(e) {
      e.preventDefault();
      formOpen = true;
      $('body').addClass('js-body--lock');
      formOverlay.fadeToggle();
    });

    $('a[href=".close"]').click(function(e) {
      e.preventDefault();
      formOpen = false;
      $('body').removeClass('js-body--lock');
      formOverlay.fadeToggle();
    });

    $(document).keyup(function(e) {
        if (e.keyCode === 27 && formOpen) {
          formOpen = false;
        $('body').removeClass('js-body--lock');
        formOverlay.fadeToggle();
        }
    });
  }

  // Reveal scroll 
  Reveal = function() {
    $( '.js-reveal:in-viewport' ).run(function() {
      if ( !$(this).hasClass('js-reveal--revealed') ) {
        var $this = $(this);
          $this.addClass('js-reveal--revealed');
      }
    });
  };

  // Owl slider
  function Owl() {
    var owlOptions = {
        smartSpeed:450,
        loop:true,
        margin:0,
        autoHeight:true,
        nav:true,
        items:1,
        navText: [arrow, arrow],
        random: true,
    };

    $('.js-carousel').owlCarousel(owlOptions);
  }

  $(document).ready(function() {
    windowHeight = $(window).height();

    new SizeContent();
    new ContactFormOverlay();
    new Owl();

    if($('body').hasClass('page-template-template-process')) {
      new ScrollAnimes(windowHeight);
    }
    
    if($('body').hasClass('page-template-template-surveylab')) {
      new SurveylabBlock();
    }

  });

  $(window).load(function() {

    if($('body').hasClass('page-template-template-process')) {
      $('.process__loader').fadeOut( 500, function() {
        $(this).remove();
      });
    }

    if($('.js-reveal').length) {
      new Reveal();
    }

    if($('body').hasClass('home')) {
      new FrontPageAboutBlock();
      new SurveylabBlock();
      new ProcessBlock();
    }

  });

  $(window).resize(function() {
    windowHeight = $(window).height();

    new SizeContent();

    console.log(Foundation.MediaQuery.current);
  });

  $(document).scroll(function() {
    var scrolledTop = $(document).scrollTop();
    
    if(scrolledTop >= 200) {
      $('.contact-button').addClass('js-visible');
    } else {
      $('.contact-button').removeClass('js-visible');
    }

    if(scrolledTop >= 100) {
      $('body').addClass('js-scrolled');
    } else {
      $('body').removeClass('js-scrolled');
    }

    if($('.js-reveal').length) {
      new Reveal();
    }

    if($('body').hasClass('home')) {
      new FrontPageAboutBlock();
      new SurveylabBlock();
      new ProcessBlock();
    }

    if($('body').hasClass('page-template-template-surveylab')) {
      new SurveylabBlock();
    }

    var fromFooter = $(document).height() - $('body > footer').outerHeight() - $(window).height();

    if($('body').hasClass('page-template-template-process') && !Foundation.MediaQuery.atLeast('medium')) {
      if(scrolledTop >= fromFooter) {
        $('.menu--process').addClass('menu--process--stick-bottom');
      } else {
        $('.menu--process').removeClass('menu--process--stick-bottom');
      }
    }

  });

})(jQuery);